import {
  GET_CLUSTER_QUERY,
  GET_SPARK_QUERY,
  GET_SPARK_IS_SHARED_QUERY,
  GET_CLUSTER_IS_SHARED_QUERY,
} from "@/apollo/queries";
import {
  useGetClusterIsSharedQuery,
  useGetSparkIsSharedQuery,
} from "@/app/__generated__/gql";
import { Cluster, Spark } from "@/types";
import { useQuery } from "@apollo/client";
import { usePathname } from "next/navigation";
import { useEffect, useState } from "react";

// const sparkPathRegex = /^\/spark\/[^/]+$/;
// const clusterPathRegex = /^\/cluster\/[^/]+$/;

// TODO: from layout
// TODO: this only works on page refresh and not on client-side navigation
// if (pathname?.startsWith("/collaboration")) {
//   isSharedPage = true;
// } else if (sparkPathRegex.test(pathname as string)) {
//   const id = pathname?.split("/")[2];

//   if (id) {
//     spark = await getSpark(id);
//     if (spark?.shared) {
//       isSharedPage = true;
//     }
//   }
// } else if (clusterPathRegex.test(pathname as string)) {
//   const id = pathname?.split("/")[2];

//   if (id) {
//     cluster = await getCluster(id);
//     if (cluster?.shared) {
//       isSharedPage = true;
//     }
//   }
// } else {
//   isSharedPage = false;
// }

const useIsViewingSharedContent = () => {
  const pathname = usePathname();
  const [isViewingShared, setIsViewingShared] = useState<boolean>(false);
  const [spark, setSpark] = useState<Spark>();
  const [cluster, setCluster] = useState<Cluster>();
  const [contentType, setContentType] = useState<"SPARK" | "CLUSTER">();
  const [id, setId] = useState<string>();

  // TODO: replace with lighter is shared query
  // useQuery(GET_SPARK_IS_SHARED_QUERY, {
  //   variables: {
  //     input: {
  //       id,
  //     },
  //   },
  //   skip: !id || contentType !== "SPARK", // dont fetch if no spark id
  //   onCompleted: (data) => {
  //     setSpark(data.spark);
  //   },
  // });

  useGetSparkIsSharedQuery({
    variables: {
      args: {
        id: id ?? "",
      },
    },
    skip: !id || contentType !== "SPARK", // dont fetch if no spark id
    onCompleted: (data) => {
      setSpark(data?.getSparkIsShared as Spark);
    },
  });

  // TODO: replace with lighter is shared query
  useQuery(GET_CLUSTER_IS_SHARED_QUERY, {
    variables: {
      input: {
        id,
      },
    },
    skip: !id || contentType !== "CLUSTER", // dont fetch if no cluster id
    onCompleted: (data) => {
      setCluster(data.cluster);
    },
  });
  // useGetClusterIsSharedQuery({
  //   variables: {
  //     args: {
  //       id,
  //     },
  //   },
  //   skip: !id || contentType !== "CLUSTER", // dont fetch if no cluster id
  //   onCompleted: (data) => {
  //     setCluster(data?.getClusterIsShared as Cluster);
  //   },
  // });

  useEffect(() => {
    // TODO: can we avoid network calls if we know its shared from url?
    // if (pathname.includes("/public/")) {
    //   setIsViewingShared(true);

    //   if (pathname.includes("/public/spark/")) {
    //     setContentType("SPARK");
    //     const sparkId = pathname.split("/")[3];
    //     setId(sparkId);
    //   }

    //   if (pathname.includes("/public/cluster/")) {
    //     setContentType("CLUSTER");
    //     const clusterId = pathname.split("/")[3];
    //     setId(clusterId);
    //   }
    // }
    if (pathname === "/spark/add") {
      setIsViewingShared(true);
      setContentType(undefined);
      setId(undefined);
      setSpark(undefined);
      setCluster(undefined);
      return;
    }

    if (pathname.includes("/spark/")) {
      setContentType("SPARK");
      const sparkId = pathname.includes("/edit/")
        ? pathname.split("/")[3]
        : pathname.split("/")[2];
      setId(sparkId);
    }

    if (pathname.includes("/cluster/")) {
      setContentType("CLUSTER");
      const clusterId = pathname.split("/")[2];
      setId(clusterId);
    }
  }, [pathname]);

  useEffect(() => {
    if (spark) {
      setIsViewingShared(spark.shared);
    } else if (cluster) {
      setIsViewingShared(cluster.shared);
    }
  }, [spark, cluster]);

  return {
    isViewingShared,
    contentType,
    content: spark || cluster,
  };
};

export default useIsViewingSharedContent;
